<template>
	<div class="container">
		<div class="row">
			<div class="col">
				<h1>Users</h1>
				<ul class="list-group mb-3">
					<li class="list-group-item d-flex" :class="{'list-group-item-light': user.hidden == 1}" v-for="(user, index) in users" :key="index">
						<router-link :to="'/user-edit/' + user.id" class="btn badge rounded-pill bg-primary">Edit</router-link>
						<span class="me-auto ms-3"> {{ user.first_name }} {{ user.last_name }} [{{ user.username }}] </span>
						<span v-if="user.role_name != 'admin'">
							<button class="btn badge rounded-pill bg-secondary" v-if="user.hidden == 0" @click="updateHidden(false, user.id)">Deactivate</button>
							<button class="btn badge rounded-pill bg-secondary" v-else @click="updateHidden(true, user.id)">Activate</button>
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>

	<div class="d-block mt-5 pt-5"></div>
</template>

<script>
import UserService from '../services/user.service';

export default {
	name: 'AppUsers',
	data() {
		return {
			users: null,
			currentUser: null,
			currentUserId: null,
			updateProgess: false,
			localstorageUser: JSON.parse(localStorage.getItem('user')),
		};
	},
	methods: {
		retrieveUser(id) {
			UserService.get(id)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.currentUser = response.data.user;
					} else {
						this.currentUser = null;
						this.$toast.warning(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		retrieveUsers() {
			UserService.getAll()
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.users = response.data.users;
					} else {
						this.users = null;
						this.$toast.warning(response.data.error.message);
					}
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		updateHidden(state, id) {
			const data = {
				hidden: state ? 0 : 1,
			};
			this.updateProgess = true;
			UserService.update(id, data)
				.then((response) => {
					// Check if success, if not show error message
					if (!response.data.error) {
						this.$toast.success(response.data.success.message);
					} else {
						this.$toast.info(response.data.error.message);
					}
					this.updateProgess = false;
					this.retrieveUsers();
				})
				.catch((e) => {
					this.catchError(e);
				});
		},
		catchError(e) {
			// Check if not authorized, if not show error message
			if (e.response.status === 401) {
				this.$store.dispatch('auth/logout');
				this.$router.push('/login');
			} else {
				this.updateProgess = false;
				console.log(e);
			}
		},
	},
	computed: {
		loggedIn() {
			return this.$store.state.auth.status.loggedIn;
		},
	},
	created() {
		this.retrieveUsers();
		this.currentUserId = this.$store.state.auth.user.id;
		this.retrieveUser(this.currentUserId);
	},
	mounted() {},
};
</script>
